import React, { useCallback, useMemo, useState } from 'react'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { HomeFeedTab } from '~/pages/feed/HomeFeed'
import { useTitle } from '~/common/hooks/useTitle'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import {
  GetFollowingPostsDocument,
  GetFollowingPostsFullDocument,
  GetMyCommunitiesPostsDocument,
  GetMyCommunitiesPostsFullDocument,
  GQLCommunitySortEnum,
  GQLGetFollowingPostsQuery,
  GQLGetFollowingPostsQueryVariables,
  GQLGetMyCommunitiesPostsQuery,
  GQLSubscriptionInfo,
  useGetMyCommunitiesQuery,
  useGetNewFeedPostActivityLazyQuery,
  useNewCommunityActivityAlertSubscription,
} from '~/api/generated/graphql'
import { useAuth, useSearchCacheContext } from '~/auth/Auth'
import { FeedActivityList } from '~/pages/feed/FeedActivityList'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'
import { useApolloClient } from '@apollo/client'

const HomeFeedActivity = ({ homeFeedTab, pageSize }: { homeFeedTab: HomeFeedTab; pageSize?: number }) => {
  const { breakpoint } = useWindowSize()
  const { isVeevan } = useAuth()
  const [postExpanded, setPostExpanded] = useState<boolean>(false)
  const { loadingContent } = useSearchCacheContext()
  useTitle('Feed - Veeva Connect')

  const {
    previewData: postsData,
    loading: postsLoading,
    error,
    loadMore,
    hasMore,
    fullData: fullPostsData,
  } = useBackgroundFetch<GQLGetMyCommunitiesPostsQuery, GQLGetFollowingPostsQueryVariables>(
    GetMyCommunitiesPostsDocument,
    GetMyCommunitiesPostsFullDocument,
    {
      variables: { pageSize: pageSize ?? 25 },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )

  const {
    previewData: followingPostsQueryData,
    loading: followingPostsLoading,
    error: followingPostsError,
    loadMore: followingLoadMore,
    hasMore: followingHasMore,
    fullData: fullFollowingData,
  } = useBackgroundFetch<GQLGetFollowingPostsQuery, GQLGetFollowingPostsQueryVariables>(
    GetFollowingPostsDocument,
    GetFollowingPostsFullDocument,
    {
      variables: { pageSize: pageSize ?? 25 },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )
  const {
    loading: loadingPostUsers,
    processedPostIds,
    loadingFirstPage: loadingFirstPostUsers,
  } = useLoadObjUsers(postsData?.posts, 'postId')
  const {
    loading: loadingFollowingUsers,
    processedPostIds: processedFollowingPostIds,
    loadingFirstPage: loadingFirstFollowingUsers,
  } = useLoadObjUsers(followingPostsQueryData?.posts, 'postId')
  useLoadObjUsers(fullPostsData?.posts, 'postId')
  useLoadObjUsers(fullFollowingData?.posts, 'postId')

  const [newActivity, setNewActivity] = useState<GQLSubscriptionInfo[]>([])

  const { data: userCommunitiesData } = useGetMyCommunitiesQuery({
    variables: { communityFilter: {}, communitySort: [GQLCommunitySortEnum.NameAsc] },
    fetchPolicy: 'cache-only',
  })

  const communityIds = useMemo(() => {
    return userCommunitiesData?.currentUser?.communities?.edges?.map(e => e?.node?.communityId) || []
  }, [userCommunitiesData])

  useNewCommunityActivityAlertSubscription({
    variables: { communityIds: communityIds },
    onSubscriptionData: async ({ subscriptionData }) => {
      if (subscriptionData.data && subscriptionData.data.newCommunityActivityAlert) {
        const p = subscriptionData.data.newCommunityActivityAlert
        const activity = [...newActivity]
        if (!activity.find(a => a.objId === p.objId)) {
          activity.push(p)
        }
        setNewActivity(activity)
      }
    },
    skip: !isVeevan,
  })

  const [getPostActivity] = useGetNewFeedPostActivityLazyQuery()
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    const ids = newActivity.map(a => (a.parentId ? a.parentId : a.objId))
    const { data: postActivity } = await getPostActivity({ variables: { postIds: ids }, fetchPolicy: 'network-only' }) // update cache so post activity time is reflected correctly
    const oldData = client.readQuery({
      query: GetMyCommunitiesPostsDocument,
      variables: { pageSize: pageSize ?? 25 },
    })

    let newPosts = [...(oldData?.posts?.edges ?? [])]
    const nonDraftIndex = newPosts.findIndex(p => !p?.node?.draft)

    // remove any duplicates, which usually means we are reordering a post higher due to recent activity
    newPosts.splice(nonDraftIndex, 0, ...(postActivity?.posts?.edges ?? []))
    newPosts = newPosts.filter((p1, i, arr) => arr.findIndex(p2 => p1?.node?.postId === p2?.node?.postId) === i)

    const newPostsData = {
      ...oldData,
      posts: {
        ...oldData?.posts,
        edges: newPosts?.slice(0, pageSize ?? 25),
      },
    }

    client.writeQuery({
      query: GetMyCommunitiesPostsDocument,
      variables: { pageSize: pageSize ?? 25 },
      data: newPostsData,
    })
    setNewActivity?.([])
  }, [client, getPostActivity, newActivity, pageSize])

  const postIds = useMemo(() => {
    if (postsData) {
      return postsData.posts?.edges
        .map(edge => edge?.node?.postId)
        .filter(id => Boolean(id) && processedPostIds?.has(id ?? '')) as string[]
    }
  }, [postsData, processedPostIds])

  const followingPostIds = useMemo(() => {
    if (followingPostsQueryData) {
      return followingPostsQueryData.posts?.edges
        .map(edge => edge?.node?.postId)
        .filter(id => Boolean(id) && processedFollowingPostIds?.has(id ?? '')) as string[]
    }
  }, [followingPostsQueryData, processedFollowingPostIds])

  const isCondensed = breakpoint <= SizeBreakpoint.lg

  return (
    <div className={`recent-activity ${isCondensed ? 'condensed' : ''}`}>
      {homeFeedTab == HomeFeedTab.My ? (
        <FeedActivityList
          postIds={postIds}
          loading={(postsLoading && !postsData) || loadingContent || loadingFirstPostUsers}
          loadingMore={postsLoading || loadingPostUsers}
          loadMore={loadMore}
          hasMore={hasMore}
          error={error}
          feedOption={homeFeedTab}
          setExpanded={setPostExpanded}
          newActivity={newActivity}
          showNewActivity={showNewActivity}
        />
      ) : (
        <FeedActivityList
          postIds={followingPostIds}
          loading={(followingPostsLoading && !followingPostsQueryData) || loadingContent || loadingFirstFollowingUsers}
          loadingMore={followingPostsLoading || loadingFollowingUsers}
          loadMore={followingLoadMore}
          hasMore={followingHasMore}
          error={followingPostsError}
          feedOption={homeFeedTab}
          setExpanded={setPostExpanded}
          newActivity={newActivity}
          showNewActivity={showNewActivity}
        />
      )}
    </div>
  )
}

export default HomeFeedActivity
